<template>
  <div class="flex flex-col md:flex-row min-h-screen">
    <Navigation />
    <div class="flex-1 overflow-hidden"> <!-- Ensure this div doesn't exceed the viewport -->
      <div class="flex flex-col h-full overflow-y-auto"> <!-- Scrollable area -->
        <div class="max-w-4xl min-w-full  mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 class="text-3xl font-bold mb-6 text-gray-900">Document Processing</h1>
          <div class="mb-4">
            <p class="text-lg font-medium mb-2">Select the type of inspection:</p>
            <div class="flex space-x-2">
              <button @click="selectInspectionType('4Point')" :class="['button-inspection', selectedInspectionType === '4Point' ? 'button-inspection-selected' : 'button-inspection-available']">4-Point Inspection</button>
              <button @click="selectInspectionType('WindMitigation')" :class="['button-inspection', selectedInspectionType === 'WindMitigation' ? 'button-inspection-selected' : 'button-inspection-available']">Wind Mitigation Inspection</button>
            </div>
          </div>      
          <div class="mb-4">
            <FileUpload name="demo[]" url="/api/upload" @upload="onTemplatedUpload($event)" multiple accept="image/*,application/pdf" :maxFileSize="20000000" @select="onSelectedFiles">
              <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                <div class="flex flex-wrap justify-between items-center flex-1 gap-4">
                  <div class="flex gap-2">
                    <Button @click="chooseCallback()" icon="pi pi-images" rounded outlined severity="secondary"></Button>
                    <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload" rounded outlined severity="success" :disabled="!files || files.length === 0"></Button>
                    <Button @click="clearCallback()" icon="pi pi-times" rounded outlined severity="danger" :disabled="!files || files.length === 0"></Button>
                  </div>
                  <ProgressBar :value="totalSizePercent" :showValue="false" class="md:w-20rem h-1 w-full md:ml-auto">
                    <span class="whitespace-nowrap">{{ totalSize }}B / 1Mb</span>
                  </ProgressBar>
                </div>
              </template>
              <template #content="{ files, removeFileCallback }">
                <div class="flex flex-col gap-4 pt-2">
                  <div v-if="files.length > 0">
                    <h5>Files</h5>
                    <div class="flex flex-wrap gap-2">
                      <div v-for="(file, index) of files" :key="file.name + file.type + file.size" class="p-4 rounded-border flex flex-col border border-surface items-center gap-2">
                        <div>
                          <template v-if="file.type === 'application/pdf'">
                            <Icon icon="vscode-icons:file-type-pdf2" width="160" height="80" />
                          </template>
                          <template v-else>
                            <img role="presentation" :alt="file.name" :src="file.objectURL" width="80" height="40" />
                          </template>
                        </div>
                        <span class="font-semibold text-ellipsis max-w-40 whitespace-nowrap overflow-hidden">{{ file.name }}</span>
                        <div>{{ formatSize(file.size) }}</div>
                        <Badge :value="file.status" :severity="file.status === 'Completed' ? 'success' : (file.status === 'Processing' ? 'info' : 'warn')" />
                        <Button icon="pi pi-times" @click="onRemoveTemplatingFile(file, removeFileCallback, index)" outlined rounded severity="danger" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #empty>
                <div class="flex items-center justify-center flex-col">
                  <i class="pi pi-cloud-upload border-2 rounded-full p-8 text-4xl text-muted-color" />
                  <p class="mt-6 mb-0">Drag and drop files to here to upload.</p>
                </div>
              </template>
            </FileUpload>
          </div>
          <Button :label="buttonLabel" @click="showResults ? (showResultsModal = true) : handleFileUpload()" :loading="loading" :disabled="!(selectedFile && selectedFile.length) || loading" class="button"/>
          <Button @click="reset" class="button-blue ml-2">Reset &#10227;</Button>
        
          <div v-if="loading" class="mt-4">
            <ProgressBar mode="indeterminate" style="height: 6px;"></ProgressBar>
            <span class="text-gray-700">{{ progressMessage }}</span>
          </div>
          <div v-if="success" class="success-message">Processing successful!</div>
          <div v-if="error" class="error-message">Error: {{ errorMessage }}</div>
        
          <!-- GPT Results Section -->
          <div v-if="showResultsModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ease-out">
            <div class="bg-white rounded-xl shadow-2xl p-8 max-w-4xl  w-full mx-4 transform transition-transform duration-300 ease-out scale-95 max-h-[70vh] overflow-y-auto">
              <div class="flex justify-end">
                <button @click="showResultsModal = false" class="text-gray-600 hover:text-gray-900 text-2xl font-bold">&times;</button>
              </div>
              <div class="flex flex-row items-center space-x-2 mb-6">
                <p class="text-xl font-semibold bg-blue-500 text-white px-2 py-1 rounded-xl">{{ inspectionTitle }}</p>
                <p class="italic font-semibold">Extracted</p>
              </div>              
              <div v-for="(section, sectionKey) in gptResults" :key="sectionKey" class="mb-6">
                <h3 class="text-xl font-semibold mb-2 cursor-pointer flex items-center transition-colors duration-300 ease-in-out hover:text-gray-800" @click="toggleVisibility(sectionKey)">
                  {{ formatKey(sectionKey) }} <!-- Format section key to be more readable -->
                  <svg v-if="visibleKeys.includes(sectionKey)" class="w-4 h-4 ml-2 transform rotate-180 transition-transform duration-300 ease-in-out" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                  <svg v-else class="w-4 h-4 ml-2 transform transition-transform duration-300 ease-in-out" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                </h3>
                <div v-if="visibleKeys.includes(sectionKey)" class="ml-4 transition-all duration-300 ease-in-out">
                  <div v-for="(itemValue, itemKey) in section" :key="itemKey" class="mb-2">
                    <strong class="font-medium text-gray-700">{{ formatKey(itemKey) }}: </strong>
                    <span v-if="typeof itemValue === 'object' && !Array.isArray(itemValue)">
                      <!-- Nested object -->
                      <div v-for="(subValue, subKey) in itemValue" :key="subKey" class="ml-4">
                        <strong class="font-medium text-gray-700">{{ formatKey(subKey) }}: </strong>{{ subValue || 'N/A' }}
                      </div>
                    </span>
                    <span v-else class="text-gray-600">
                      {{ itemValue || 'N/A' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Navigation from '../components/Navigation.vue';
import Button from 'primevue/button';
import Badge from 'primevue/badge';
import FileUpload from 'primevue/fileupload';
import ProgressSpinner from 'primevue/progressspinner';
import ProgressBar from 'primevue/progressbar';
import Echo from 'laravel-echo';
import { Icon } from '@iconify/vue';
import Pusher from 'pusher-js';

export default {
  components: {
    Navigation,
    Button,
    ProgressSpinner,
    ProgressBar,
    Badge,
    FileUpload,
    Icon
  },
  data() {
    return {
      selectedFile: [],
      files: [],
      uploadedFiles: [],
      gptResults: {}, // Change to object to handle results properly
      loading: false,
      success: false,
      error: false,
      errorMessage: '',
      jobId: null,
      timer: null, // Timer to handle timing out or delays
      progressMessage: '',
      progressPercentage: 0,
      totalSize: 0,
      totalSizePercent: 0,
      copied: false,
      visibleKeys: [],
      collapsedSubKeys: [],
      showResults: false,
      showResultsModal: false,
      selectedInspectionType: '4Point',
    };
  },
  computed: {
    buttonLabel() {
      return this.showResults ? 'Show GPT Results' : 'Process Files';
    },
    inspectionTitle() {
      return this.selectedInspectionType === '4Point' ? '4-Point Inspection' : 'Wind Mitigation Inspection';
    }
  },
  methods: {
    selectInspectionType(type) {
      this.selectedInspectionType = type;
    },
    toggleVisibility(key) {
      const index = this.visibleKeys.indexOf(key);
      if (index > -1) {
        this.visibleKeys.splice(index, 1);
      } else {
        this.visibleKeys.push(key);
      }
    },
    toggleSubVisibility(key) {
      const index = this.collapsedSubKeys.indexOf(key);
      if (index > -1) {
        this.collapsedSubKeys.splice(index, 1);
      } else {
        this.collapsedSubKeys.push(key);
      }
    },
    formatKey(key) {
      // Convert key to a string to ensure the .replace method can be safely used
      key = String(key);
      return key.replace(/_/g, ' ').replace(/(\b[a-z](?!\s))/g, char => char.toUpperCase());
    },
    formatSize(size) {
      if (size > 1024 * 1024) return (size / (1024 * 1024)).toFixed(2) + ' MB';
      if (size > 1024) return (size / 1024).toFixed(2) + ' KB';
      return size + ' bytes';
    },
    formatJson(jsonData) {
      try {
        return JSON.stringify(jsonData, null, 2);
      } catch (e) {
        console.error('Error formatting JSON:', e);
        return jsonData;
      }
    },
    handleFileSelect(event) {
          const files = event.target.files;
          console.log('Files selected:', files);
          if (!files.length) {
            this.reset();
            return;
          }
          this.selectedFile = Array.from(files).filter(file => this.validateFile(file));
          this.error = this.selectedFile.length !== files.length;
        },
        validateFile(file) {
      if (!['application/pdf', 'image/jpeg', 'image/png'].includes(file.type)) {
        this.errorMessage = 'Unsupported file type.';
        return false;
      }
      if (file.size > 20 * 1024 * 1024) { // 20MB limit
        this.errorMessage = 'File size exceeds 20 MB.';
        return false;
      }
      return true;
    },

    onSelectedFiles(event) {
        const validFiles = event.files.filter(file => this.validateFile(file));
        this.selectedFile = validFiles; // Always set selectedFile, even if it's an empty array
        validFiles.forEach(file => {
            file.status = 'Selected';
        });
        this.files.push(...validFiles);
        this.updateTotalSize();
    },
    onRemoveTemplatingFile(file, removeFileCallback, index) {
      removeFileCallback(file);
      this.files.splice(index, 1);
      this.totalSize = this.files.reduce((acc, file) => acc + file.size, 0);
      this.totalSizePercent = (this.totalSize / (1024 * 1024)) * 100;
    },
    onTemplatedUpload(event) {
        event.files.forEach(file => {
            const index = this.files.findIndex(f => f.name === file.name && f.size === file.size);
            if (index !== -1) {
                this.files[index].status = 'Uploaded';
            }
        });
        this.updateTotalSize();
    },
    updateTotalSize() {
      this.totalSize = this.files.reduce((acc, file) => acc + file.size, 0);
      this.totalSizePercent = (this.totalSize / (1024 * 1024)) * 100;
    },
    async handleFileUpload() {
      this.loading = true;
      this.progressMessage = 'Uploading files...';
      const formData = new FormData();
      if (this.selectedFile && this.selectedFile.length > 0) {
        this.selectedFile.forEach((file, index) => {
          // Append actual file objects to formData
          formData.append('file[]', file);
          formData.append('inspectionType', this.selectedInspectionType); // Add selected inspection type to formData
          // Update status in an immutable way
          this.selectedFile[index] = { ...file, status: 'Processing' };
        });

        try {
          const response = await axios.post('/api/upload', formData, {
            onUploadProgress: progressEvent => {
              this.progressPercentage = parseInt((progressEvent.loaded * 100) / progressEvent.total);
            }
          });
          this.jobId = response.data.job_id;
          this.setupWebSocketListener();
        } catch (error) {
          this.handleError(error);
        }
      } else {
        this.loading = false;
        console.error("No valid files selected for upload.");
      }
    },
    setupWebSocketListener() {
      const echo = new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
        forceTLS: true,
      });

      echo.private(`job-status.${this.jobId}`)
        .listen('JobStatusUpdated', (event) => {
          if (event.status === 'completed') {
            this.handleResults(event);
          } else if (event.status === 'error') {
            this.handleError({ message: event.message });
          }
        });
    },
    handleResults(data) {
    console.log("Received data:", data);
      if (data.result && typeof data.result.content === 'string') {
        this.gptResults = JSON.parse(data.result.content); // Parse JSON content
        this.files.forEach(file => {
          if (this.selectedFile.includes(file)) {
            file.status = 'Completed'; // Directly setting the status without using $set
          }
        });
        this.success = true;
        this.progressMessage = 'Processing completed successfully.';
        this.showResults = true; // Set showResults to true
      } else {
        console.error("Invalid data.result structure:", data.result);
        this.handleError({ message: "Invalid result structure received." });
      }
      this.loading = false;
    },
    handleError(error) {
      this.error = true;
      this.errorMessage = error.message || 'An error occurred during file processing.';
      this.loading = false;
    },
    reset() {
      this.selectedFile = [];
      this.files = [];
      this.uploadedFiles = [];
      this.gptResults = {};
      this.loading = false;
      this.success = false;
      this.error = false;
      this.errorMessage = '';
      this.progressMessage = '';
      this.progressPercentage = 0;
      this.totalSize = 0;
      this.totalSizePercent = 0;
      this.copied = false;
      this.visibleKeys = [];
      this.collapsedSubKeys = [];
      this.showResults = false;
      this.showResultsModal = false;
      this.selectedInspectionType = '4Point';
      this.jobId = null;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },


    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.copied = true;
        setTimeout(() => this.copied = false, 2000); // Reset after 2 seconds
      });
    }
  },
  beforeUnmount() {
    if (this.echo) {
      this.echo.leave(`job-status.${this.jobId}`);
    }
  }
};
</script>


<style scoped>
input[type="file"] {
  @apply mt-2 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500;
}
.progress-bar {
  @apply w-full h-2 bg-gray-300 rounded-full overflow-hidden;
}
.progress-bar-inner {
  @apply bg-blue-500 h-full rounded-full transition-all duration-300 ease-in-out;
}
pre {
  @apply overflow-auto text-sm bg-white p-4 rounded-lg shadow-inner border border-gray-200;
}
.button {
  @apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
.button-blue {
  @apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400;
}
.button-red {
  @apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500;
}
.button-green {
  @apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500;
}
.button-inspection {
  @apply inline-flex items-center px-6 py-3 border border-transparent text-sm font-medium rounded-md shadow-md;
}

.button-inspection-selected {
  @apply bg-indigo-500 text-white cursor-pointer;
}

.button-inspection-available {
  @apply bg-indigo-200 text-indigo-400 cursor-pointer hover:bg-indigo-300;
}
.section-title {
  @apply text-xl font-semibold mb-4 text-gray-900;
}
.results-container {
  @apply mt-8 overflow-auto max-h-96 p-6 bg-gray-50 border border-gray-200 rounded-lg;
}
.result-display {
  @apply bg-white p-4 rounded-lg shadow-lg;
}
.result-title {
  @apply font-semibold text-lg;
}
.result-table {
  @apply w-full table-fixed;
  border-collapse: collapse;
}
.result-table th, .result-table td {
  @apply border px-4 py-2;
  border-color: rgba(0, 0, 0, 0.1);
}
.item-key {
  @apply font-semibold text-right bg-gray-100;
}
.item-value {
  @apply text-left;
}
.error-message, .success-message {
  @apply mt-4 p-4 text-sm font-medium rounded-md;
}
.error-message {
  @apply bg-red-100 text-red-800;
}
.success-message {
  @apply bg-green-100 text-green-800;
}
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
