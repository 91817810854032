<template>
    <div v-if="showModal" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
      <div class="bg-white rounded-lg p-8 shadow-lg text-center">
        <h2 class="text-2xl font-semibold mb-4">Unauthorized Access</h2>
        <p class="mb-6">You do not have the required permissions to access this page.</p>
        <button @click="closeModal" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">
          OK
        </button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      showModal: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>
  