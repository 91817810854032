<template>
  <div class="menu-container">
    <Menubar :model="items" class="bg-white">
      <template #start>
        <a href="/">
          <img
            src="@/assets/images/Insurance_express_logo.png"
            class="block md:hidden"
          />
        </a>
      </template>

      <template #item="{ item, props, hasSubmenu, root }">
        <a
          v-ripple
          class="flex align-items-center"
          v-bind="props.action"
          :class="{
            'router-link': item.type === 'router',
            'external-link': item.type === 'external',
          }"
          :to="item.to"
          :href="item.to"
          @click="trackEvent('MainMenu', item.label, item.type)"
        >
          <!-- Show PrimeVue icon for 'Customer Service' initially then switch to Iconify -->
          <template v-if="item.label === 'Customer Service'">
            <span
              v-if="!showIconifyIcon"
              :class="[item.icon, 'text-red-500']"
            ></span>
            <Icon v-else icon="line-md:heart-filled" class="text-red-500" />
          </template>
          <!-- For other menu items, continue using their respective icons -->
          <template v-else>
            <span :class="item.icon"></span>
          </template>
          <span class="ml-2">{{ item.label }}</span>
          <Badge
            v-if="item.badge"
            :class="{ 'ml-auto': !root, 'ml-2': root }"
            :value="item.badge"
          />
          <span
            v-if="item.shortcut"
            class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1"
            >{{ item.shortcut }}</span
          >
          <i
            v-if="hasSubmenu"
            :class="[
              'pi pi-angle-down text-primary',
              { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root },
            ]"
          ></i>
        </a>

        <!-- Submenu -->
        <ul v-if="hasSubmenu">
          <li v-for="(submenuItem, index) in item.items" :key="index">
            <a
              v-ripple
              :class="{
                'router-link': submenuItem.type === 'router',
                'external-link': submenuItem.type === 'external',
              }"
              :to="submenuItem.to"
              :href="submenuItem.to"
              @click="
                trackEvent('Submenu', submenuItem.label, submenuItem.type)
              "
            >
              {{ submenuItem.label }}
            </a>
          </li>
        </ul>
      </template>

      <!-- <template #end>
        <div class="align-items-center gap-2 hidden md:flex">
          <router-link to="/login" class="text-primary flex items-center">
            <i class="pi pi-user mr-2"></i> Register/Login
          </router-link>
        </div>
      </template> -->
      <!-- <template #end>
        <div class="align-items-center gap-2 hidden md:flex">
          <router-link to="/login" class="text-primary flex items-center">
            <i class="pi pi-user mr-2"></i> Login
          </router-link>
        </div>
      </template> -->
    </Menubar>
  </div>
</template>

<script>
import Menubar from 'primevue/menubar';
import Badge from 'primevue/badge';
import InputText from 'primevue/inputtext';
import { Icon } from '@iconify/vue';

export default {
  components: {
    Menubar,
    Badge,
    InputText,
    Icon,
  },
  data() {
    return {
      showIconifyIcon: false,
      items: [
        {
          label: 'Home Page',
          icon: 'pi pi-home',
          to: '/',
          type: 'router',
        },
        {
          label: 'Insurance Products',
          icon: 'pi pi-briefcase',
          items: [
            {
              label: 'Home Insurance',
              to: '/products/homeinsurance',
              type: 'router',
            },
            {
              label: 'Auto Insurance',
              to: '/products/autoinsurance',
              type: 'router',
            },
            {
              label: 'Flood Insurance',
              to: '/products/floodinsurance',
              type: 'router',
            },
            {
              label: 'Life Insurance',
              to: '/products/lifeinsurance',
              type: 'router',
            },
            {
              label: 'Medicare',
              to: '/products/medicare',
              type: 'router',
            },
            {
              label: 'Business Insurance',
              to: '/products/businessinsurance',
              type: 'router',
            },
            {
              label: 'Umbrella Insurance',
              to: '/products/umbrellainsurance',
              type: 'router',
            },

            // Add more insurance products as needed
          ],
        },
        {
          label: 'Member Companies',
          icon: 'pi pi-users', // You can change the icon as needed
          items: [
            {
              label: 'QuoteSlash',
              to: 'https://quoteslash.com/',
              type: 'external',
            },
            {
              label: 'Rocket Flood',
              to: 'https://rocketflood.com/',
              type: 'external',
            },
            {
              label: 'Rocket MGA',
              to: 'https://rocketmga.com/',
              type: 'external',
            },
            {
              label: 'TheLeadExchange',
              to: 'http://www.theleadexchange.com/',
              type: 'external',
            },
            {
              label: 'Str8line Group',
              to: 'http://www.str8linegroup.com/',
              type: 'external',
            },
            {
              label: 'Str8line Enterprises',
              to: 'http://www.str8linegroup.com/',
              type: 'external',
            },
            {
              label: 'Protego VIP',
              to: 'https://protegovip.com/',
              type: 'external',
            },
            {
              label: 'NuBuild',
              to: 'https://nubuildinsurance.com/',
              type: 'external',
            },
            {
              label: 'The IE Pipeline',
              to: 'https://theiepipeline.com/',
              type: 'external',
            },
            {
              label: 'Graham Newton & McMahon Inc.',
              to: 'https://gnminsurance.com/',
              type: 'external',
            },
          ],
        },
        {
          label: 'About Us',
          icon: 'pi pi-info-circle',
          to: '/aboutus',
          type: 'router',
        },
        {
          label: 'Customer Service',
          icon: 'pi pi-heart',
          to: '/customerservice',
          type: 'router',
        },
        // Conditionally add Register/Login for mobile view
        // {
        //   label: 'Register/Login',
        //   icon: 'pi pi-user',
        //   to: '/login',
        //   type: 'router',
        //   // Condition to show this item only in mobile view
        //   visible: window.innerWidth < 768, // Adjust the breakpoint as needed
        // },
        // {
        //   label: 'Login',
        //   icon: 'pi pi-user',
        //   to: '/login',
        //   type: 'router',
        //   // Condition to show this item only in mobile view
        //   visible: window.innerWidth < 768, // Adjust the breakpoint as needed
        // },
      ],
    };
  },
  mounted() {
    setInterval(() => {
      this.showIconifyIcon = !this.showIconifyIcon;
    }, 5000);
  },
  methods: {
    trackEvent(menuLevel, label, type) {
      let eventLabel = `${menuLevel}: ${label} (${type})`;
      this.$gtag.event('click', {
        event_category: 'Navigation',
        event_label: eventLabel,
      });
    },
  },
};
</script>

<style scoped>
.menu-container {
  position: relative;
  z-index: 21;
}
.search-input-border {
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
}
</style>
