import { createApp } from 'vue';
import axios from 'axios';
import router from './router.js';
import store from './store/index.js';
import '../css/app.css';
import SpinnerComponent from './components/SpinnerComponent.vue'; // Import the spinner component

// Adjusted PrimeVue imports with .esm.js extension
import PrimeVue from 'primevue/config/config.esm.js';
import ToastService from 'primevue/toastservice/toastservice.esm.js';
import Toast from 'primevue/toast/toast.esm.js';
import Ripple from 'primevue/ripple/ripple.esm.js';
import DataTable from 'primevue/datatable/datatable.esm.js';
import Column from 'primevue/column/column.esm.js';
import MultiSelect from 'primevue/multiselect/multiselect.esm.js';
import Button from 'primevue/button/button.esm.js';
import Paginator from 'primevue/paginator/paginator.esm.js';
import Editor from 'primevue/editor/editor.esm.js';
import ProgressSpinner from 'primevue/progressspinner'; // Newly added
import FileUpload from 'primevue/fileupload'; // Newly added
import ProgressBar from 'primevue/progressbar'; // Newly added
import Card from 'primevue/card'; // Newly added
import Notifications from '@kyvg/vue3-notification';

// Other imports
import VueGtag from 'vue-gtag';
import Echo from 'laravel-echo';
import Vue3Signature from 'vue3-signature';
import Pusher from 'pusher-js';
import VueMask from '@devindex/vue-mask';

// PrimeVue styles
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

// Set up Pusher
window.Pusher = Pusher;

// Configure Laravel Echo
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  forceTLS: true,
  encrypted: true,
  csrfToken: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
});

function setupChatwoot(callback) {
  window.chatwootSettings = {
    type: 'expanded_bubble',
    launcherTitle: 'Chat with us',
  };

  const script = document.createElement('script');
  script.src = 'https://insuranceexpresschat.com/packs/js/sdk.js';
  script.async = true;
  script.onload = () => {
    window.chatwootSDK.run({
      websiteToken: '5MUh9hiKzyW9fE3MtxJHnzh9',
      baseUrl: 'https://insuranceexpresschat.com',
    });
    callback();  // Callback to mount the Vue app after Chatwoot is ready
  };
  document.head.appendChild(script);
}

// Create Vue application
const app = createApp({
  template: '<router-view></router-view>',
});

// Configure Axios
axios.defaults.baseURL = 'https://insuranceexpress.online'; //import.meta.env.VITE_APP_URL || 'https://insuranceexpress.com'; // Use your actual API URL
axios.defaults.withCredentials = true; // This is crucial for sending cookies

app.config.errorHandler = (err, vm, info) => {
  console.error(`Error: ${err.toString()}\nInfo: ${info}`);
};

// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(`Axios error: ${error.response?.status} - ${error.message}`);
    if (error.response && error.response.status === 401) {
      // Token has expired or user is unauthorized
      store.dispatch('logout').then(() => {
        router.push('/login');
      });
    }
    return Promise.reject(error);
  },
);

// Register global components and plugins
app.use(PrimeVue, { ripple: true });
app.use(router);
app.use(store);
app.use(Notifications);
app.use(ToastService);
app.use(VueGtag, { config: { id: 'G-B9F0111JPC' } }, router);
app.use(Vue3Signature);
app.use(VueMask, {
  placeholders: {
    ' ': /\s/, // Define space as a placeholder
  },
});
app.directive('ripple', Ripple);

// Register PrimeVue components globally
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Paginator', Paginator);
app.component('MultiSelect', MultiSelect);
app.component('Button', Button);
app.component('Editor', Editor);
app.component('Toast', Toast);
app.component('ProgressSpinner', ProgressSpinner);
app.component('FileUpload', FileUpload);
app.component('ProgressBar', ProgressBar);
app.component('Card', Card);

// Register SpinnerComponent globally
app.component('SpinnerComponent', SpinnerComponent);

// Register Axios globally
app.config.globalProperties.$axios = axios;

// Mount the app after Chatwoot is fully setup
setupChatwoot(() => app.mount('#app'));
